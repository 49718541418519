import AsyncStorage from "@react-native-async-storage/async-storage";
import { postData } from "../../networking/api";

export const projectTypeValueById = async () => {
    let projectData = await AsyncStorage.getItem("projectAllData")
    projectData = JSON.parse(projectData)
    if (projectData) {
        const project_type_id = projectData?.project_type_id;
        const payload = JSON.stringify({
            module: "project_type",
            condition: {
                id: project_type_id,
            }
        });
        const response = await postData(payload, "masters/getMasterList");
        if (response.code == 1) {
            let returnValue = response?.data[0].value ? response?.data[0].value : "IRRIGATION";
            return returnValue
        }
        return "IRRIGATION";
    }
}

