import {
  StyleSheet,
  TouchableOpacity,
  // TextInput,
  Button,
  ImageBackground,
  Image,
} from "react-native";
import React, { useEffect, useState } from "react";
import IconSelect from "react-native-vector-icons/AntDesign";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { RadioButton, Snackbar } from "react-native-paper";
import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
import { postMethod } from "../../networking/api";
import Icon from "react-native-vector-icons/AntDesign";
import IconCross from "react-native-vector-icons/Entypo";
import { TextInput } from "react-native-paper";
import { Dropdown } from "react-native-element-dropdown";

export default function LoginScreen({ navigation }) {
  //   {
  //   navigation,
  // }: RootStackScreenProps<"Login">
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [emailData, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [isEmailValid, setEmailValid] = useState(false);
  const [isEmailEntered, setEmailEntered] = useState(false);
  const [isPassValid, setPassValid] = useState(false);
  const [isPassEntered, setPassEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [isErrorAlert, setErrorAlertVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [UserTypeError, setUserTypeError] = useState("");

  const [MobileNumber, setMobileNumber] = useState("");
  const [isMobileNumberValid, setMobileNumberValid] = useState(true);
  const [isMobileNumberEntered, setMobileNumberEntered] = useState(false);

  const [mobileNumError, setMobileNumError] = useState("");

  const [isPasswordSecure, setIsPasswordSecure] = useState(true);

  const [invalidCredential, setInvalidCredential] = useState("");
  const regex = /^[0-9]*$/;
  // Irrigation start value
  const [userTypeData, setUserTypeData] = useState([
    {
      id: 1,
      name: "Administrative",
    },
    {
      id: 2,
      name: "Farmer",
    },
  ]);
  const [userTypeDataId, setUserTypeDataId] = useState("");
  const [userTypeDataValue, setUserTypeDataValue] = useState("");
  const [isAssignchecked,setAssignChecked]= useState(1);

  // console.log("invalidCredential", invalidCredential);

  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(/^[a-zA-Z0-9]/);
  useEffect(() => {
    setPasswordError("");
    setEmailError("");
    // setUserTypeError("");
  }, []);
  const onLoginPress = () => {
    setEmailEntered(true);
    setPassEntered(true);
    // if (isEmailValid && isPassValid) {
    //   // console.log("onLoginPress");
    //   // //alert(password);
    //   getLogin(emailData, password);
    //   // navigation.navigate("Home");
    // }
    if (emailData == "") {
      setEmailError("Please Enter Email id");
    }
    if (password == "") {
      setPasswordError("Please Enter password");
    }
    // if (userTypeDataId == "") {
    //   setUserTypeError("Please select user type");
    // }
    // if (userTypeDataId !== "") {
    // if (isEmailValid && isPassValid) {
    //   getLogin(emailData, password);
    // }

     /////////////// add Mobile Number Field For Login -RohitB ///////

    if (isAssignchecked === 1) {
      // For Admin login with email
      if (!isEmailValid || !isPassValid) {
        // Check if email and password are valid
        return;
      }
      getLogin(emailData, password, 1); // Ensure the user type value is a number
    } else if (isAssignchecked === 2) {
      // For Farmer login with mobile number
      if (!isMobileNumberValid || !isPassValid) {
        // Check if mobile number and password are valid
        return;
      }
      getLogin(MobileNumber, password, 2); // Ensure the user type value is a number
    }
    // }
  };
  const renderUserType = (item: any) => {
    // console.log("irrigation ", item);
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.name}</Text>
        {item.id === userTypeDataId && (
          <IconSelect
            style={styles.icon}
            color="black"
            name="Safety"
            size={20}
          />
        )}
      </View>
    );
  };
  //Login API   // add UserType variable- RohitB
  const getLogin = async (username: string, pass: string,isAssignchecked: number) => {
    setIsLoading(true);

    try {
      var loginData = JSON.stringify({
        username: username,
        password: pass,
        userType: isAssignchecked,
      });
      // console.log("inside getlogin", loginData);

      const LogCustomerResponse = await postMethod(
        loginData,
        "auth/login/userLogin"
      );

      setIsLoading(false);

      var code = LogCustomerResponse.code;
      if (code == 1) {
        try {
          await AsyncStorage.setItem(
            "userData",
            JSON.stringify(LogCustomerResponse.data)
          );
          await AsyncStorage.setItem(
            "userToken",
            LogCustomerResponse.accesstoken
          );
          await AsyncStorage.setItem(
            "userTypes",
            JSON.stringify(LogCustomerResponse.userType)
          );
        } catch (error) {
          // console.log("Something went wrong", error);
        }
        // //alert(LogCustomerResponse.message);
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(true);
          {
            navigation.navigate("Home");
            // LogCustomerResponse.userType == 1
            //   ? navigation.navigate("Home")
            //   : navigation.navigate("EditFarmerDetails", {
            //     farmerId: LogCustomerResponse.data.id,
            //   });
          }
        }, 0);

        // console.log("success log:", LogCustomerResponse.data);
      } else {
        setIsLoading(false);
        // console.log("LogCustomerResponse.message", LogCustomerResponse.message);

        setEmailError("");
        setPasswordError(LogCustomerResponse.message);
        setInvalidCredential(LogCustomerResponse.message);
        // setErrorAlertVisible(true);
        // setTimeout(() => {
        //   setErrorAlertVisible(true);
        //   // navigation.navigate("Home");
        // }, 3000);

        // console.log("failed log");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const logSuccess = () => {
    // console.log("Snack Bar");
    return (
      <View
        style={{
          position: "absolute",
          top: 111,
          right: "18%",
          // backgroundColor: "#06911d",
          // opacity: 0.6,
          // right: 20,
        }}
      >
        <Snackbar
          visible={isAlertVisible}
          onDismiss={() => setAlertVisible(false)}
        // action={{
        //   label: "Undo",

        //   onPress: () => {
        //     // Do something
        //   },
        // }}
        // style={{ backgroundColor: "black" }}
        >
          <View
            style={{
              flexDirection: "row",
              padding: 20,
              backgroundColor: "#3be357b3",
            }}
          >
            <Text style={{ color: "#fff" }}>
              <Icon color="white" name="checkcircle" size={20} />
              Successfully Logged in
            </Text>
          </View>
        </Snackbar>
      </View>
    );
  };
  const logError = () => {
    // console.log("Snack Bar");
    return (
      <View
        style={{
          position: "absolute",
          top: 111,
          right: "18%",
          // backgroundColor: "#06911d",
          // opacity: 0.6,
          // right: 20,
        }}
      >
        <Snackbar
          visible={isErrorAlert}
          onDismiss={() => setErrorAlertVisible(true)}
        // action={{
        //   label: "Undo",

        //   onPress: () => {
        //     // Do something
        //   },
        // }}
        // style={{ backgroundColor: "black" }}
        >
          <View style={{ padding: 20, backgroundColor: "red" }}>
            <Text style={{ color: "#fff" }}>
              {" "}
              <IconCross color="white" name="circle-with-cross" size={20} />
              InValid Credentials
            </Text>
          </View>
        </Snackbar>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <View style={{ flex: 1, margin: 10 }}>
        <Image
          source={{
            // uri: "https://png.pngtree.com/png-vector/20200901/ourlarge/pngtree-blue-wavy-shapes-on-transparent-background-png-image_2336605.jpg",
            uri: "https://cloud.bermad.io/static/media/icon.01a28814.png",
          }}
          style={styles.imageContainer}
        />
        <Text style={styles.logoText}></Text>
        {/* Add Mobile Number Field based on User Type--- RohitB */}
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
       <Text style={{marginLeft:5}}>Admin</Text>
       <RadioButton
        value="1"
        status={isAssignchecked === 1 ? "checked" : "unchecked"}
        onPress={() => {setAssignChecked(1);}}
       />
       <Text>Farmer</Text>
       <RadioButton
         value="2"
         status={isAssignchecked === 2 ? "checked" : "unchecked"}
         onPress={() => {setAssignChecked(2);}}
        />
        </View>
        </View>
        {isAssignchecked === 2 && (
        <View>
          <Text>Mobile No.</Text>
          <TextInput
            style={styles.loginFormTextInput}
            placeholder=""
            placeholderColor="#c4c3cb"
            value={MobileNumber}
            maxLength={10}
            keyboardType={"numeric"}
            onChangeText={(value) => {
              setMobileNumberValid(false);
              setMobileNumber(value);
              if (value.length == 0) {
                setMobileNumError("Please enter mobile number");
              } else if (!regex.test(value)) {
                setMobileNumError("Please enter number only");
              } else if (value.length > 0 && value.length <= 9) {
                setMobileNumError("Please enter 10 digit number");
              } else {
                setMobileNumberValid(true);
                setMobileNumError("");
              }
            }}
          />
          <Text style={[styles.error_text]}>{mobileNumError}</Text>
        </View>
        )}
        {isAssignchecked === 1 && (
        <View>
          <Text>Email</Text>

          <TextInput
            placeholder=""
            placeholderColor="#c4c3cb"
            style={styles.loginFormTextInput}
            onChangeText={(value) => {
              setEmailValid(false);
              setEmailError(
                isEmailEntered && value == "" ? "Please Enter Email id" : ""
              );
              setEmail(value);
              setButtonDisabled(true);
              // console.log("if 1", value);
              if (Email_Id_pattern.test(value)) {
                setEmailValid(true);
                // console.log("if 2", value);
                setButtonDisabled(true);
              } else {
                setEmailError("Please Enter Email id");
              }
            }}
          />
          {EmailError && <Text style={[styles.error_text]}>{EmailError}</Text>}
        </View>
        )}

        <View>
          <Text>Password</Text>
          <TextInput
            placeholderColor="#F0F0F0"
            autoCapitalize="none"
            autoCorrect={false}
            style={styles.loginFormTextInput}
            secureTextEntry={isPasswordSecure ? true : false}
            // value={isPasswordSecure}
            right={
              <TextInput.Icon
                name={() => (
                  <IconCross
                    name={isPasswordSecure ? "eye-with-line" : "eye"}
                    size={20}
                    color="#000"
                  />
                )} // where <Icon /> is any component from vector-icons or anything else
                // onPress={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                onPress={() => setIsPasswordSecure(!isPasswordSecure)}
              />
            }
            onChangeText={(value) => {
              setPassValid(false);
              setPasswordError(
                isPassEntered && value == "" ? "Please Enter password" : ""
              );
              setPass(value);
              setButtonDisabled(true);
              // console.log("if 1", value);
              if (password_pattern.test(value)) {
                setPassValid(true);
                // console.log("if 2", value);
                // //alert(value);
                setButtonDisabled(false);
              } else {
                setPasswordError("Please Enter password");
              }
            }}
          />
          <View style={{ marginTop: 0 }}>
            {passwordError && (
              <Text style={[styles.error_text]}>{passwordError}</Text>
            )}
          </View>
          {/* {!isEmailEntered && !isPassEntered && !invalidCredential && (
            <Text style={[styles.error_text]}></Text>
          )} */}
          {/* {invalidCredential !== "" && (
            <Text style={[styles.error_text]}>{invalidCredential}</Text>
          )} */}
        </View>

        <TouchableOpacity
          // disabled={isButtonDisabled}
          style={styles.loginButton}
          onPress={onLoginPress}
        >
          <Text style={styles.loginText}>Login</Text>
        </TouchableOpacity>
        <Text
          onPress={() => navigation.navigate("Reset")}
          style={styles.forgotText}
        >
          Forgot Password?
        </Text>
      </View>
      <View style={{ flex: 3, height: "100%" }}>
        <Image
          source={{
            // uri: "https://png.pngtree.com/png-vector/20200901/ourlarge/pngtree-blue-wavy-shapes-on-transparent-background-png-image_2336605.jpg",
            uri: "https://cloud.bermad.io/static/media/bg.3e3fdc8e.jpg",
          }}
          style={{
            // flex: 1,
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
      {/* {logSuccess()} */}
      {logError()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center", // ignore this - we'll come back to it
    justifyContent: "center", // ignore this - we'll come back to it
    flexDirection: "row",
    padding: 10,
  },
  imageContainer: {
    height: 100,
    width: "100%",
    resizeMode: "contain",
    marginVertical: 10,
  },
  loginFormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 5,
    // borderWidth: 1,
    borderColor: "#fafafa",
    backgroundColor: "#F0F0F0",
    // borderBottomWidth: 1,
    paddingLeft: 0,
    marginTop: 5,
    marginBottom: 5,
  },
  logoText: {
    fontSize: 40,
    fontWeight: "800",
    marginTop: 50,
    marginBottom: 30,
    textAlign: "center",
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  loginButton: {
    alignItems: "center",
    backgroundColor: "#2196f3",
    padding: 10,
    borderWidth: 1,
    borderColor: "#2196f3",
    marginTop: 20,
  },
  loginText: {
    color: "#fff",
    fontWeight: "600",
  },
  forgotText: {
    color: "#000",
    fontWeight: "600",
    marginTop: 10,
    textAlign: "center",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  // DropDown CSS

  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },

  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 12,
    alignSelf: "center",
  },
  selectedTextStyle: {
    fontSize: 16,
    alignSelf: "center",
  },
  // iconStyle: {
  //     width: 20,
  //     height: 20,
  // },
  iconStyle: {
    width: 0,
    height: 0,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  labelTitleTBD: {
    color: "#707070",
    padding: 10,
    fontSize: 20,
  },
  labelTitle: {
    padding: 15,
    color: "#0089D1",
    fontWeight: "600",
    textTransform: "Capitalize",
  },
});
