//distesting
// export const baseUrl = "https://bermadapi.disctesting.in/api/";
// export const baseUrlimage = "https://bermadapi.disctesting.in/";
// export const baseUrlSocket = "ws://139.162.32.130:8080";
// export const baseUrlSocket = "https://bermadapi.disctesting.in/";

//production
// export const baseUrl = "https://api.bermadize.in/api/";
// export const baseUrlimage = "https://api.bermadize.in/";
// export const baseUrlSocket = "wss://socket.bermadize.in/";
// "https://bermadize.in/" change this url in package.json file

//staging
// export const baseUrl = "https://stageapi.bermadize.in/api/";
// export const baseUrlimage = "https://stageapi.bermadize.in/";
// export const baseUrlSocket = "wss://stagesocket.bermadize.in/";
// "https://stageadmin.bermadize.in/" change this url in package.json file

//local
// export const baseUrlSocket = "http://192.168.10.187:3000/";
// export const baseUrlimage = "http://192.168.10.187:3000/";
// export const baseUrl = "http://192.168.10.187:3000/api/";

var environment = "production"
var BaseUrl = ""
var BaseImageurl = ""
var BaseUrlSocket = ""

if(environment == "local"){
  BaseUrl = "http://localhost:3000/api/";
  BaseImageurl =  "http://localhost:3000/";
  // BaseUrlSocket = "http://localhost:3000/";
  BaseUrlSocket = "wss://socket.bermadize.in/";
}
if(environment == "testing"){
  BaseUrl = "https://bermadapi.disctesting.in/api/";
  BaseImageurl =  "https://bermadapi.disctesting.in/";
  // BaseUrlSocket = "ws://139.162.32.130:8080";
  BaseUrlSocket = "wss://stagesocket.bermadize.in/";
}
// "https://bermadapi.disctesting.in/" change this url in package.json file
if(environment == "staging"){
  BaseUrl = "https://stageapi.bermadize.in/api/";
  BaseImageurl = "https://stageapi.bermadize.in/";
  BaseUrlSocket = "wss://stagesocket.bermadize.in/";
}
// "https://stageadmin.bermadize.in/" change this url in package.json file
if(environment == "production"){
  BaseUrl = "https://api.bermadize.in/api/";
  BaseImageurl = "https://api.bermadize.in/";
  BaseUrlSocket = "wss://socket.bermadize.in/";
}
// "https://bermadize.in/" change this url in package.json file


export const baseUrl = BaseUrl;
export const baseUrlimage = BaseImageurl;
export const baseUrlSocket = BaseUrlSocket;


import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions, useNavigation } from "@react-navigation/native";
import ResetPass from "../screens/ResetPassword/ResetPass";

export const postMethod = async (data: string, apiName: string) => {
  var options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  };
  const response = await fetch(baseUrl + apiName, options)
    .then(async (result) => {
      const response = await result.json();
      // console.log("post method login", response);
      return response;
    })
    .catch((error) =>
      console.log("error", error));
  return response;
};

export const farmerPostMethod = async (data: string, apiName: string) => {
  var options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  };

  const response = await fetch(baseUrl + apiName, options)
    .then(async (result) => {
      const response = await result.json();
      // console.log("farmer post method login", response);
      return response;
    })
    .catch((error) =>
      console.log("error", error));
  return response;
};

export const postData = async (data: string, apiName: string) => {
  // const navigation = useNavigation();
  const farmerTokenData = await AsyncStorage.getItem("farmerToken");

  const userToken = await AsyncStorage.getItem("userToken");
  // console.log("userToken:", userToken);

 const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: userToken ? userToken : farmerTokenData || '',
    };

    const options = {
      method: 'POST',
      headers: headers,
      body: data,
    };
  // console.log("Payload==>", options);
  const response = await fetch(baseUrl + apiName, options)
    .then(async (result) => {
      const response = await result.json();
      // console.log("response of postdata", response);
      if (response.token === 0) {
        window.location.href = "/login";
      } else {
        return response;
      }
    })
    .catch((error) => {
      console.log("error", error);
      // navigation.navigate("Login");
    });
  return response;
};

export const getMethod = (apiName: string) => {
  var options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  fetch(baseUrl + apiName, { options })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      // console.log(error);
    })
    .then((response) => {
      return response.json;
    });
};

export const updateMethod = async (data: any, apiName: any) => {
  var options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  };
  const response = await fetch(baseUrl + apiName, options)
    .then(async (result) => {
      const response = await result.json();
      return response;
    })
    .catch((error) =>
      console.log("error", error));
  return response;
};

export const deleteMethod = (id: any, apiName: any) => {
  var options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  fetch(baseUrl + apiName + id, { options })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      // console.log(error);
    })
    .then((response) => {
      return response.json;
    });
};
